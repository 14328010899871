export function screenDetector() {
  const windowWidth = window.innerWidth;
  if (windowWidth >= 1920) {
    return "xl-desktop";
  } else if (windowWidth >= 1280 && windowWidth < 1920) {
    return "desktop";
  } else if (windowWidth >= 990 && windowWidth < 1280) {
    return "tablet";
  } else {
    return "mobile";
  }
}
