<template>
  <div class="services column no-gap">
    <div v-if="loading" class="centered-loading-wrapper">
      <LoadingSpinner />
    </div>
    <NoDataWrap v-if="!loading && services.length < 1">
      No Services
    </NoDataWrap>
    <template v-else>
      <table class="service-outlets">
        <tr>
          <td v-for="date in this.dates" :key="date">
            <h2 class="service-outlets__date no-gap">
              {{ formatDay(date) }}
              {{ formatDate(date, "dd/MM") }}
            </h2>
          </td>
        </tr>
        <tr v-for="outlet in outlets" :key="outlet.id">
          <td v-for="date in this.dates" :key="date">
            <div
              class="service-outlet"
              v-if="getService(date, outlet)"
              @click="goToDetailPage(date, outlet)">
              <h3 class="ellipsis">
                <span
                  class="dot"
                  :style="`background-color: ${
                    getService(date, outlet).outlet.color
                  };`" />
                {{ outlet.code }}
              </h3>
              <p class="service-outlet__venue row">
                <template v-if="getService(date, outlet).venue">
                  <Icon
                    :path="OPERATORTYPEVENUEICON"
                    :size="10"
                    :style="`color: ${
                      getService(date, outlet).venue.color
                    };`" />
                  <span class="service-outlet__name ellipsis">
                    {{ getService(date, outlet).venue.name }}
                  </span>
                </template>
              </p>
              <p class="service-outlet__time row">
                <Icon :path="mdiClock" :size="11" />{{
                  formatTime(getService(date, outlet).startTime)
                }}
                -
                {{ formatTime(getService(date, outlet).endTime) }}
              </p>
              <p class="service-outlet__menu row">
                <template v-if="getService(date, outlet).menu">
                  <Icon :path="mdiFood" :size="10" />
                  <span class="service-outlet__name ellipsis">
                    {{ getService(date, outlet).menu.name }}
                  </span>
                </template>
              </p>
            </div>
          </td>
        </tr>
      </table>
    </template>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import store from "@/store";
  import { mdiClock, mdiFood } from "@mdi/js";
  import { OPERATORTYPEVENUE } from "@/constants/operatorTypes.ts";
  import { barChartColours, barChartColours2 } from "@/helpers/chartColours";
  import NoDataWrap from "@/components/NoDataWrap.vue";
  import { APICOMPOSITEEVENTQUERY_INCLUDEMENUS } from "@tucktrucks/platform-base-private";
  import { OPERATORTYPEVENUEICON } from "@/constants/operatorTypes.ts";

  export default {
    data() {
      return {
        operatorType: this.$route.params.operatorType,
        operatorId: parseInt(this.$route.params.operatorId),
        OPERATORTYPEVENUE,
        loading: false,
        numberOfUpcomingEvents: 7,
        venues: [],
        menus: [],
        events: [],
        services: [],
        outlets: [],
        dates: [],
        mdiClock,
        mdiFood,
        OPERATORTYPEVENUEICON,
      };
    },

    components: {
      NoDataWrap,
    },

    computed: {
      ...mapGetters({}),
    },

    methods: {
      isOperatorTypeVenue() {
        return false;
      },

      getUpcomingEvents() {
        this.loading = true;

        store.state.apiPrivate.client.endpoints.events
          .getWithQuery({
            includeVenues: true,
            includeServices: true,
            includeOutlets: true,
            includeMenus: APICOMPOSITEEVENTQUERY_INCLUDEMENUS.HEADER,
            upcoming: true,
            take: this.numberOfUpcomingEvents,
            vendors: [this.operatorId],
          })
          .then((response) => {
            if (response.status == 200) {
              return response.data.data;
            }
          })
          .then((data) => {
            this.venues = data.venues ?? [];
            this.events = data.events ?? [];
            this.services = data.services ?? [];
            this.menus = data.menus ?? [];
            this.outlets =
              data.outlets?.sort((a, b) => (a.name > b.name ? 1 : -1)) ?? [];

            const colors = barChartColours(this.outlets.length);
            this.outlets = this.outlets.map((outlet, index) => {
              outlet.index = index;
              outlet.color = colors[index];
              return outlet;
            });

            const colors2 = barChartColours2(this.venues.length);
            this.venues = this.venues.map((venue, index) => {
              venue.index = index;
              venue.color = colors2[index];
              return venue;
            });

            this.services = this.services.map((service) => {
              service.event = this.events.find((x) => x.id == service.eventId);
              service.venue = this.venues.find((x) => x.id == service.venueId);
              service.menu = this.menus.find((x) => x.id == service.menuId);
              service.outlet = this.outlets.find(
                (x) => x.id == service.outletId
              );
              return service;
            });

            this.dates = [
              ...new Set(
                this.services
                  .sort((a, b) => (a.date > b.date ? 1 : -1))
                  .map((x) => x.date)
              ),
            ];
          })
          .finally(() => (this.loading = false));
      },

      getService(date, outlet) {
        return this.services.find(
          (x) => x.date == date && x.outletId == outlet.id
        );
      },

      getMenu(date, outlet) {
        return this.getService(date, outlet).menu;
      },

      goToDetailPage(date, outlet) {
        const service = this.getService(date, outlet);

        this.$router.push({
          name: "/service",
          params: {
            operatorType: this.operatorType,
            operatorId: this.operatorId,
            serviceId: service.id,
          },
        });
      },
    },

    mounted() {
      this.getUpcomingEvents();
    },
  };
</script>

<style lang="scss" scoped>
  .service-outlets {
    width: 100%;
    table-layout: fixed;
    border: none;
    border-collapse: collapse;
    border-spacing: 0;

    td {
      border-left: 5px solid #eee;
      border-right: 5px solid #eee;
    }

    td:first-child {
      border-left: none;
    }
    td:last-child {
      border-right: none;
    }

    .service-outlet {
      width: 100%;
      margin: 0.125rem;
      padding: 0.25rem;
      border-radius: 0.25rem;
      cursor: pointer;
      padding: 0.5em;

      &:hover {
        background: $col_alpha-lightest;
      }

      h3,
      p {
        margin: 0;
      }

      h3 {
        font-size: 1rem;
        font-weight: bold;
        line-height: 1.5rem;

        .dot {
          display: inline-block;
          width: 12px;
          height: 12px;
          border-radius: 50%;
        }
      }

      p {
        @include themed() {
          color: t($g10);
        }

        font-size: 0.8rem;
        line-height: 1.3rem;
      }

      &__time,
      &__menu,
      &__venue {
        display: flex;
        gap: 0.25rem;
        flex-wrap: nowrap;

        .mdi-icon {
          display: inline-block;
        }
      }
    }
  }

  h2 {
    font-size: 1.2rem;
    padding: 0.5em;
  }
</style>
