import Enumerable from "linq";

export function getServiceTime(services, byProp, isEarliest) {
  const index = isEarliest ? 0 : services.length - 1;
  const sortedServices = Enumerable.from(services)
    .orderBy((x) => x[byProp])
    .toArray();

  const service = sortedServices[index];

  if (service == null) {
    return service;
  }

  return service[byProp];
}
