<template>
  <div class="calendar-outlet">
    <Calendar
      :data="data"
      @select="showServicePicker($event)"
      @rangeChange="showYearMonth($event)" />
  </div>
  <teleport to="body">
    <transition name="fade">
      <ModalDialog
        v-if="showSelectServices"
        class="vendor-services modal"
        @close="showSelectServices = false"
        :maxWidth="'450'">
        <template v-slot:title>Select Service</template>

        <p>
          There are {{ selectServices?.length ?? 0 }} services on
          {{ formatDate(selectDate) }}. Please choose which you wish to view.
        </p>

        <div v-for="service in selectServices" :key="service.id">
          <button @click="goToServiceDetail(service.id)">
            <h6>{{ service.startTime }} - {{ service.endTime }}</h6>
          </button>
        </div> 
      </ModalDialog>
    </transition>
  </teleport>
</template>

<script>
  import Calendar from "@/components/Calendars/Calendar.vue";
  import ModalDialog from "@/components/Dialogs/ModalDialog.vue";
  import { getMonthQuery, createDot } from "@/helpers/calendar";

  export default {
    data() {
      return {
        data: [],
        events: [],
        services: [],
        venues: [],
        showSelectServices: false,
        selectDate: "",
        selectServices: [],
      };
    },

    components: {
      Calendar,
      ModalDialog
    },

    props: {
      outletIds: {
        type: Array,
        required: true,
      },
    },

    methods: {
      updateData(date) {
        let query = getMonthQuery(date);
        query.includeServices = true;
        query.includeVenues = true;
        query.outlets = this.outletIds;

        this.$store.state.apiPrivate.client.endpoints.events
          .getWithQuery(query)
          .then((response) => {
            if (response.status === 200) {
              return response.data.data;
            }
          })
          .then((data) => {
            this.events = data.events;
            this.services = data.services;
            this.venues = data.venues;

            this.data = this.services.map((service) => {
              let event = this.events.find((x) => x.id == service.eventId);
              let venue = this.venues.find((x) => x.id == event.venue.id);
              let index = this.venues.indexOf(venue);

              return createDot(
                index,
                this.venues.length,
                venue.name,
                service.date
              );
            });
          })
          .catch((ex) => {
            window.log.error(ex);
          });
      },

      showYearMonth(event) {
        this.updateData(new Date(event.year, event.month - 1, 1));
      },

      showServicePicker(clicked) {
        
        const services = this.services.filter((x) => x.date == clicked.id);

        if (services.length == 1) {
          this.goToServiceDetail(services[0].id);
        } else if (services.length > 1) {
          this.selectDate = clicked.id;
          this.selectServices = services;
          this.showSelectServices = true;
        }
      },

      goToServiceDetail(serviceId) {
        const params = this.$route.params;
        this.$router.push({
          path: `/${params.operatorType}/${params.operatorId}/service/${serviceId}`,
        });
      },
    },
  };
</script>

<style lang="scss">
  .calendar-outlet {
    display: flex;
    width: 100%;
    height: 100%;
  }
</style>
