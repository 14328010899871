<template>
  <div class="services column no-gap">
    <div v-if="loading" class="centered-loading-wrapper">
      <LoadingSpinner />
    </div>
    <no-data-wrap v-if="!loading && services.length < 1">
      No Services
    </no-data-wrap>
    <template v-else>
      <div
        class="service-item row"
        v-for="service in services"
        :key="service.id"
        @click="goToDetailPage(service)">
        <div class="info column">
          <div class="date row">
            <Icon :path="mdiCalendarRange" :size="16" />
            <p class="row no-gap">
              <span class="dayname">
                {{ this.formatDay(service.date) }}
              </span>
              <span class="dot"></span>
              {{ this.formatDate(service.date) }}
            </p>
          </div>
          <div class="time row">
            <Icon :path="mdiClock" :size="14" />
            <p>{{ service.startTime }} - {{ service.endTime }}</p>
          </div>
        </div>

        <div class="details column">
          <div class="details-content column">
            <div class="assigned-venue row">
              <Icon
                :path="OPERATORTYPEVENUEICON"
                :size="16"
                class="venue-icon"
                :style="`color:${service.venue.color};`" />
              <span class="name">{{ service.venue.name }}</span>
            </div>
          </div>
          <div v-if="service.menu" class="details-content column">
            <div class="assigned-menu row">
              <Icon :path="mdiFood" :size="16" class="menu-icon" />
              <span class="name">{{ service.menu.name }}</span>
            </div>
          </div>
          <div v-else>
            <p class="missing-menu row">
              <Icon :path="mdiAlertCircle" class="alert-icon" :size="17" />
              Menu Missing
            </p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import store from "@/store";
  import NoDataWrap from "@/components/NoDataWrap.vue";
  import { barChartColours } from "@/helpers/chartColours";
  import { APICOMPOSITEEVENTQUERY_INCLUDEMENUS } from "@tucktrucks/platform-base-private";
  import { OPERATORTYPEVENUEICON } from "@/constants/operatorTypes.ts";
  import {
    mdiCalendarRange,
    mdiClock,
    mdiFood,
    mdiFileDocument,
    mdiAlertCircle,
  } from "@mdi/js";

  export default {
    data() {
      return {
        operatorType: this.$route.params.operatorType,
        operatorId: parseInt(this.$route.params.operatorId),
        events: [],
        services: [],
        servicesByDate: {},
        venues: [],
        loading: true,
        OPERATORTYPEVENUEICON,
        mdiCalendarRange,
        mdiClock,
        mdiFood,
        mdiFileDocument,
        mdiAlertCircle,
      };
    },

    components: {
      NoDataWrap,
    },

    computed: {
      ...mapGetters({}),
    },

    methods: {
      getUpcomingEvents() {
        this.loading = true;

        store.state.apiPrivate.client.endpoints.events
          .getWithQuery({
            includeServices: true,
            includeVenues: true,
            includeMenus: APICOMPOSITEEVENTQUERY_INCLUDEMENUS.HEADER,
            upcoming: true,
            take: 7,
            outlets: [this.operatorId],
          })
          .then((response) => {
            if (response.status == 200) {
              return response.data.data;
            }
          })
          .then((data) => {
            this.events = data.events;
            this.services = data.services;
            this.venues = data.venues;
            this.menus = data.menus ?? [];

            const colors = barChartColours(this.venues.length);
            this.venues = this.venues.map((venue, index) => {
              venue.index = index;
              venue.color = colors[index];

              return venue;
            });

            this.services = this.services.map((service) => {
              service.event = this.events.find((x) => x.id == service.eventId);
              service.venue = this.venues.find((x) => x.id == service.venueId);
              service.menu = this.menus.find((x) => x.id == service.menuId);

              return service;
            });

            this.services = this.services.sort((a, b) => {
              if (a.date > b.date) {
                return 1;
              }
              if (a.date === b.date) {
                return a.startTime > b.startTime ? 1 : -1;
              }
              return -1;
            });
          })
          .finally(() => (this.loading = false));
      },

      goToDetailPage(service) {
        const params = this.$route.params;
        this.$router.push({
          path: `/${params.operatorType}/${params.operatorId}/service/${service.id}`,
        });
      },
    },

    mounted() {
      this.getUpcomingEvents();
    },
  };
</script>

<style lang="scss" scoped>
  .services {
    margin-top: 10px;
    width: 100%;
  }

  .service-item {
    border-bottom: 1px solid $col_faded;
    padding: 10px 5px;
    cursor: pointer;
    width: calc(100% + 2rem);
    margin-left: -1rem;
    margin-right: -1rem;
    padding: 0.6rem 1rem;
    @include flex-initial($wrap: nowrap, $alignItems: baseline);

    &:hover {
      @include themed() {
        background: t($faded);
      }

      //background: #7773;
    }

    .date {
      font-size: 1rem;
      gap: 3px;
      width: 170px;

      .material-icons {
        font-size: 1rem;
      }
    }

    .time,
    .version {
      @include themed() {
        color: t($g7);
      }

      font-size: 0.8rem;
      gap: 5px;

      .material-icons {
        font-size: 0.8rem;
      }
    }

    .info {
      font-weight: normal;
      gap: 5px;
    }

    .details {
      flex-grow: 1;
      gap: 5px;

      button {
        padding: 0;

        &:hover {
          color: $col_alpha;
        }
      }
    }

    .details-content {
      gap: 5px;
      flex-grow: 1;
    }

    .assigned-venue {
      font-size: 1.1rem;
      @include flex-initial($wrap: nowrap, $alignItems: baseline);
    }

    .assigned-menu,
    .missing-menu {
      @include themed() {
        color: t($g7);
      }

      font-size: 0.8rem;
      @include flex-initial($wrap: nowrap, $alignItems: baseline);
    }

    .name {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      // white-space: nowrap;

      @media screen and (max-width: 480px) {
        width: 110px;
      }
    }

    .missing-menu,
    & > .alert-icon {
      color: $col_alpha;
    }

    p {
      margin: 0;
    }

    span.dayname {
      margin-right: 0.25rem;
    }

    span.dot {
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background: #ccc;
      display: inline-block;
      margin-right: 0.25rem;
    }
  }

  .upcomingservices.card {
    gap: 0;
    min-width: 380px !important;

    @media screen and (max-width: 480px) {
      min-width: 300px !important;

      .service-item {
        flex-wrap: nowrap;
      }

      .service-item p,
      .service-item .assigned-menu,
      .service-item .missing-menu {
        font-size: 0.875rem;
      }

      .service-item .time p,
      .service-item .version {
        font-size: 0.8rem;
      }

      .service-item .date {
        width: 130px;
        flex-wrap: nowrap;
      }
    }
  }
</style>
