<template>
  <div class="calendar-venue">
    <Calendar
      :data="data"
      @select="goToEventDetail($event)"
      @rangeChange="showYearMonth($event)" />
  </div>
</template>

<script>
  import Calendar from "@/components/Calendars/Calendar.vue";
  import { getMonthQuery, createDot } from "@/helpers/calendar";

  export default {
    data() {
      return {
        data: [],
        events: [],
        services: [],
        outlets: [],
      };
    },

    components: {
      Calendar,
    },

    props: {
      venueIds: {
        type: Array,
        required: true,
      },
    },

    methods: {
      updateData(date) {
        let query = getMonthQuery(date);
        query.includeServices = true;
        query.includeOutlets = true;
        query.venues = this.venueIds;

        this.$store.state.apiPrivate.client.endpoints.events
          .getWithQuery(query)
          .then((response) => {
            if (response.status === 200) {
              return response.data.data;
            }
          })
          .then((data) => {
            this.events = data.events;
            this.services = data.services;
            this.outlets = data.outlets;

            this.data = this.services.map((service) => {
              let outlet = this.outlets.find((x) => x.id == service.outletId);
              let index = this.outlets.indexOf(outlet);

              return createDot(
                index,
                this.outlets.length,
                outlet.name,
                service.date
              );
            });
          })
          .catch((ex) => {
            window.log.error(ex);
          });
      },

      showYearMonth(event) {
        this.updateData(new Date(event.year, event.month - 1, 1));
      },

      goToEventDetail(clicked) {
        const event = this.events.find((x) => x.startDate == clicked.id);

        if (event != null) {
          const params = this.$route.params;
          this.$router.push({
            path: `/${params.operatorType}/${params.operatorId}/event/${event.id}`,
          });
        }
      },
    },
  };
</script>

<style lang="scss">
  .calendar-venue {
    display: flex;
    width: 100%;
    height: 100%;
  }
</style>
