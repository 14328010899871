<template>
  <div class="custom-calendar-container">
    <VCalendar
      class="custom-calendar"
      :attributes="attributes"
      :min-date="minDate"
      :first-day-of-week="2"
      @dayclick="onSelect"
      @update:fromPage="onRangeChange">
    </VCalendar>
    <div v-if="data != null" class="v-calendar-legend-wrapper">
      <ul class="v-calendar-legend">
        <li
          v-for="dot in distinctLabels()"
          :key="dot.label"
          class="v-calendar-legend__row">
          <span
            :style="{ backgroundColor: dot.dot.base.style.backgroundColor }"
            class="v-calendar-legend__dot" />
          {{ dot.dot.label }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    data() {
      return {
        attributesBaseData: {
          key: "today",
          highlight: true,
          dates: new Date(),
        },
        attributes: [],
        vendors: [],
      };
    },

    emits: ["select", "rangeChange"],

    props: {
      data: {
        type: Array,
        default: null,
      },

      minDate: {
        type: String,
        default: "2000-01-01",
      },
    },

    watch: {
      data() {
        this.updateAttributes();
      },
    },

    computed: {
      ...mapGetters({}),
    },

    methods: {
      onSelect(day) {
        this.$emit("select", day);
      },

      onRangeChange(page) {
        this.$emit("rangeChange", { month: page.month, year: page.year });
      },

      updateAttributes() {
        this.attributes = [this.attributesBaseData];

        if (this.data != null) {
          this.attributes.push(...Object.values(this.data));
        }
      },

      distinctLabels() {
        return [
          ...new Map(this.data.map((item) => [item.dot.label, item])).values(),
        ];
      },
    },

    mounted() {
      this.updateAttributes();
    },
  };
</script>

<style lang="scss">
  .custom-calendar-container {
    width: 100%;
    margin-top: 1em;
  }

  .vc-container {
    border: none;

    @include themed() {
      background-color: t($g15);
    }
  }

  .vc-header {
    margin-bottom: 1.5rem;

    .vc-title {
      @include themed() {
        color: t($g4);
      }

      &:hover {
        @include themed() {
          color: t($g2);
        }
      }
    }
  }

  .highlight {
    @include themed() {
      color: t($g16);
    }
  }

  .vc-highlight {
    background: #5896fbd6 !important;

    .is-today & {
      background: $col-alpha !important;
      color: #fff !important;
    }
  }

  .vc-day-content {
    @include themed() {
      color: t($g3);
    }
  }

  .vc-weekday {
    text-align: left;
    padding-left: 1.2em;
  }

  .custom-calendar {
    width: 100%;

    .vc-dots {
      width: 28px;
      flex-wrap: wrap;

      .vc-dot {
        margin: 0 1.5px 3px 1.5px;
      }
    }

    .vc-day-box-center-center {
      padding: 0.5rem;
      align-items: flex-start;
      flex-wrap: wrap;
      width: 45px;
      gap: 2px;
    }

    .vc-day-box-center-bottom {
      align-items: flex-start;
      position: relative;
    }
  }

  .v-calendar-legend-wrapper {
    margin-top: 1em;

    .v-calendar-legend {
      // columns: 2;
      // -webkit-columns: 2;
      // -moz-columns: 2;

      li {
        display: inline-block;
        margin: 0.5em;
      }

      &__row {
        display: flex;
        align-items: center;
        margin-top: 5px;
      }

      &__dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 5px;
      }
    }
  }
</style>
