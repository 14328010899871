<template>
  <div class="main-content">
    <div class="dashboard">
      <h3 class="page-heading">Dashboard</h3>
      <div class="dashboard_content venue" v-if="isOperatorTypeVenue">
        <Card
          :flex="3"
          :tabletFull="false"
          :tabletHalf="true"
          title="Venue Calendar">
          <template #content>
            <CalendarVenue :venueIds="[operatorId]" />
          </template>
        </Card>

        <Card
          :flex="3"
          :tabletFull="false"
          :tabletHalf="true"
          title="Upcoming Events">
          <template #content>
            <UpcomingVenue :venueId="operatorId" />
          </template>
        </Card>
      </div>
      <div class="dashboard_content vendor" v-if="isOperatorTypeVendor">
        <div style="width: 100%">
          <Card :flex="3" title="Vendor Calendar">
            <template #content>
              <CalendarVendor :vendorIds="[operatorId]" />
            </template>
          </Card>
        </div>

        <Card :flex="3" title="Upcoming Services">
          <template #content>
            <UpcomingVendor :vendorId="operatorId" />
          </template>
        </Card>
      </div>
      <div class="dashboard_content outlet" v-if="isOperatorTypeOutlet">
        <Card
          :flex="3"
          :tabletFull="false"
          :tabletHalf="true"
          title="Outlet Calendar">
          <template #content>
            <CalendarOutlet :outletIds="[operatorId]" />
          </template>
        </Card>

        <Card
          :flex="3"
          :tabletFull="false"
          :tabletHalf="true"
          title="Upcoming Services">
          <template #content>
            <UpcomingOutlet :outletId="operatorId" />
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import { screenDetector } from "@/helpers/screenDetector";
  import Card from "@/components/Card.vue";
  import CalendarVenue from "@/components/Dashboard/CalendarVenue.vue";
  import UpcomingVenue from "@/components/Dashboard/UpcomingVenue.vue";
  import CalendarVendor from "@/components/Dashboard/CalendarVendor.vue";
  import UpcomingVendor from "@/components/Dashboard/UpcomingVendor.vue";
  import CalendarOutlet from "@/components/Dashboard/CalendarOutlet.vue";
  import UpcomingOutlet from "@/components/Dashboard/UpcomingOutlet.vue";
  import {
    OPERATORTYPEVENUE,
    OPERATORTYPEVENDOR,
    OPERATORTYPEOUTLET,
    //OPERATORTYPECOURIER,
  } from "@/constants/operatorTypes.ts";

  export default {
    components: {
      Card,
      CalendarVenue,
      UpcomingVenue,
      CalendarVendor,
      UpcomingVendor,
      CalendarOutlet,
      UpcomingOutlet,
    },

    data() {
      return {
        operatorType: this.$route.params.operatorType,
        operatorId: parseInt(this.$route.params.operatorId),
        layout: "desktop",
        loading: false,
        showModal: "",
        selectedServiceId: null,
        upcomingServicesKey: 0,
        viewToShow: "default",
      };
    },

    computed: {
      ...mapGetters({
        selectedOperationType: "selectedOperator/getType",
        availableOperatorsGet: "availableOperators/get",
      }),

      isOperatorTypeVenue() {
        return this.selectedOperationType === OPERATORTYPEVENUE;
      },

      isOperatorTypeVendor() {
        return this.selectedOperationType === OPERATORTYPEVENDOR;
      },

      isOperatorTypeOutlet() {
        return this.selectedOperationType === OPERATORTYPEOUTLET;
      },
    },

    methods: {
      updateIfDesktopLayoutState() {
        this.layout = screenDetector();
      },

      showServiceDetail(serviceId) {
        this.selectedServiceId = serviceId;
        this.viewToShow = "editor";
      },

      refreshServices() {
        this.upcomingServicesKey++;
      },
    },

    mounted() {
      this.updateIfDesktopLayoutState();
      window.addEventListener("resize", this.updateIfDesktopLayoutState);
    },

    unmounted() {
      window.removeEventListener("resize", this.updateIfDesktopLayoutState);
    },
  };
</script>

<style lang="scss">
  .dashboard {
    margin-top: 1rem;

    &_content {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }

    .outlet {
      .upcomingservices {
        .card__content {
          align-items: start;
        }
      }
    }
  }
</style>
