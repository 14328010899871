<template>
  <div class="events column no-gap">
    <div v-if="loading" class="centered-loading-wrapper">
      <LoadingSpinner />
    </div>
    <NoDataWrap v-if="!loading && events.length < 1">No Events</NoDataWrap>
    <template v-else>
      <div
        class="event-item row"
        v-for="event in events"
        :key="event.id"
        @click="goToDetailPage(event)">
        <div class="info column">
          <div class="date row">
            <Icon :path="mdiCalendarRange" :size="16" />
            <p class="row no-gap">
              <span class="dayname">
                 {{ this.formatDay(event.event.date) }}
              </span>
               <span class="dot"></span>
                {{ this.formatDate(event.event.date) }}
            </p>
          </div>
          <div class="time row">
            <Icon :path="mdiClock" :size="14" />

            <p>
              {{ getServiceTime(event.services, "startTime", true) }} -
              {{ getServiceTime(event.services, "endTime") }}
            </p>
          </div>
          <div class="time row">
            <Icon :path="OPERATORTYPEOUTLETICON" :size="16" />
            <p>{{ event.services.length }} Vendors</p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import store from "@/store";
  import {
    OPERATORTYPEVENUE,
    OPERATORTYPEOUTLETICON,
  } from "@/constants/operatorTypes.ts";
  import { getServiceTime } from "@/helpers/service.js";
  import {
    mdiCalendarRange,
    mdiClock,
    mdiFood,
    mdiFileDocument,
    mdiAlertCircle,
  } from "@mdi/js";
  import NoDataWrap from "@/components/NoDataWrap.vue";

  export default {
    data() {
      return {
        operatorType: this.$route.params.operatorType,
        operatorId: parseInt(this.$route.params.operatorId),
        events: [],
        OPERATORTYPEVENUE,
        OPERATORTYPEOUTLETICON,
        loading: false,
        numberOfUpcomingEvents: 7,
        getServiceTime,
        mdiCalendarRange,
        mdiClock,
        mdiFood,
        mdiFileDocument,
        mdiAlertCircle,
      };
    },

    components: {
      NoDataWrap,
    },

    computed: {
      ...mapGetters({}),
    },

    methods: {
      getUpcomingEvents() {
        this.loading = true;

        store.state.apiPublic.client.endpoints.events
          .getWithQuery({
            upcoming: true,
            take: this.numberOfUpcomingEvents,
            venues: [this.operatorId],
          })
          .then((response) => {
            if (response.status == 200) {
              this.events = response.data.data;
            }
          })
          .finally(() => (this.loading = false));
      },

      goToDetailPage(event) {
        this.$router.push({
          name: "/event",
          params: {
            operatorType: this.operatorType,
            operatorId: this.operatorId,
            eventId: event.event.id,
            event: JSON.stringify(event),
          },
        });
      },
    },

    mounted() {
      this.getUpcomingEvents();
    },
  };
</script>

<style lang="scss" scoped>
  .events {
    margin-top: 10px;
    width: 100%;
  }

  .event-item {
    border-bottom: 1px solid $col_faded;
    padding: 10px 5px;
    gap: 0.5rem !important;
    cursor: pointer;
    width: calc(100% + 2rem);
    margin-left: -1rem;
    margin-right: -1rem;
    padding: 0.6rem 1rem;

    &:hover {
      background: $col_beta-lightest;
    }

    .date {
      font-size: 1rem;
      gap: 3px;
      width: 170px;

      .material-icons {
        font-size: 1rem;
      }
    }

    .time,
    .version {
      font-size: 0.8rem;
      color: #999;
      gap: 5px;

      .material-icons {
        font-size: 0.8rem;
      }
    }

    .info {
      font-weight: normal;
      gap: 5px;
    }

    .menu-name {
      flex-grow: 1;
      gap: 5px;

      button {
        padding: 0;

        &:hover {
          color: $col_alpha;
        }
      }
    }

    .menu-name-content {
      gap: 5px;
      flex-grow: 1;
    }

    .assigned-menu,
    .missing-menu {
      font-size: 1.1rem;
      gap: 5px;
      flex-wrap: nowrap;

      .material-icons {
        font-size: 1rem;
      }
    }

    .menu-icon {
      color: $col_beta;
    }

    .name {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media screen and (max-width: 480px) {
        width: 110px;
      }
    }

    .missing-menu,
    & > .alert-icon {
      color: $col_alpha;
    }

    p {
      margin: 0;
    }

    span.dayname {
      margin-right: 0.25rem;
    }

    span.dot {
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background: #ccc;
      display: inline-block;
      margin-right: 0.25rem;
    }
  }

  .upcomingservices.card {
    gap: 0;
    min-width: 380px !important;

    @media screen and (max-width: 480px) {
      min-width: 300px !important;

      .service-item {
        flex-wrap: nowrap;
      }

      .service-item p,
      .service-item .assigned-menu,
      .service-item .missing-menu {
        font-size: 0.875rem;
      }

      .service-item .time p,
      .service-item .version {
        font-size: 0.8rem;
      }

      .service-item .date {
        width: 130px;
        flex-wrap: nowrap;
      }
    }
  }

  .events {
    height: 100%;
  }

  .service-outlets {
    width: calc(100% + 1rem);
    overflow-x: visible;
    cursor: grab;
    height: 100%;
    min-height: 200px;

    &__services {
      position: relative;
      width: 100%;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      user-select: none;
      cursor: pointer;
      height: 100%;

      margin-bottom: -1rem;

      &.active {
        cursor: grabbing;
      }
    }

    &__service {
      width: 150px;
      height: 100%;
      flex-shrink: 0;
      flex-grow: 1;
    }

    &__date {
      text-align: center;
      font-size: 1.2rem;
      border-bottom: $table_border;
      font-weight: bold;
      margin-bottom: 0;
      padding-bottom: 0.25rem;

      span {
        font-size: 0.8rem;
        font-weight: 500;
        text-transform: uppercase;
        color: $col_calendar_dayname;
      }
    }

    &__outlets {
      border-right: $table_border;
      min-height: 100%;
      gap: 0.5rem !important;
    }
  }
</style>
