<template>
  <div class="calendar-vendor">
    <Calendar
      :data="data"
      @select="showServicePicker($event)"
      @rangeChange="showYearMonth($event)" />
  </div>
  <teleport to="body">
    <transition name="fade">
      <ModalDialog
        v-if="showSelectServices"
        class="vendor-services modal"
        @close="showSelectServices = false"
        :maxWidth="'450'">
        <template v-slot:title>Select Service</template>

        <p>
          There are {{ selectServices?.length ?? 0 }} services on
          {{ formatDate(selectDate) }}. Please choose which you wish to view.
        </p>

        <div v-for="service in selectServices" :key="service.id">
          <button @click="goToServiceDetail(service.id)">
            <h5>{{ service.outlet.name }} @ {{ service.venue.name }}</h5>
            <h6>{{ service.startTime }} - {{ service.endTime }}</h6>
          </button>
        </div>
      </ModalDialog>
    </transition>
  </teleport>
</template>

<script lang="js">
  import ModalDialog from "@/components/Dialogs/ModalDialog.vue";
  import Calendar from "@/components/Calendars/Calendar.vue";
  import { getMonthQuery, createDot } from "@/helpers/calendar";

  export default {
    data() {
      return {
        data: [],
        events: [],
        services: [],
        venues: [],
        outlets: [],
        showSelectServices: false,
        selectDate: "",
        selectServices: [],
      };
    },

    components: {
      ModalDialog,
      Calendar,
    },

    props: {
      vendorIds: {
        type: Array,
        required: true,
      },
    },

    methods: {
      updateData(date) {
        let query = getMonthQuery(date);
        query.includeServices = true;
        query.includeVenues = true;
        query.includeOutlets = true;
        query.vendors = this.vendorIds;

        this.$store.state.apiPrivate.client.endpoints.events
          .getWithQuery(query)
          .then((response) => {
            if (response.status === 200) {
              return response.data.data;
            }
          })
          .then((data) => {
            this.events = data.events;
            this.outlets = data.outlets;
            this.venues = data.venues;
            this.services = data.services
              .map((service) => {
                service.outlet = this.outlets.find(
                  (x) => x.id == service.outletId
                );
                service.event = this.events.find(
                  (x) => x.id == service.eventId
                );
                service.venue = this.venues.find(
                  (x) => x.id == service.event.venue.id
                );

                return service;
              })
              .sort((a, b) => (a.name > b.name ? 1 : -1));

            this.data = this.services
              .map((service) => {
                let outlet = service.outlet;
                let index = this.outlets.indexOf(outlet);

                let dot = createDot(
                  index,
                  this.outlets.length,
                  outlet.name,
                  service.date
                );
                dot.outletId = outlet.id;
                dot.outletName = outlet.name;

                return dot;
              })
              .sort((a, b) => (a.outletName > b.outletName ? 1 : -1));
          })
          .catch((ex) => {
            window.log.error(ex);
          });
      },

      showYearMonth(event) {
        this.updateData(new Date(event.year, event.month - 1, 1));
      },

      showServicePicker(clicked) {
        const services = this.services.filter((x) => x.date == clicked.id);

        if (services.length == 1) {
          const params = this.$route.params;
          this.$router.push({
            path: `/${params.operatorType}/${params.operatorId}/service/${services[0].id}`,
          });
        } else if (services.length > 1) {
          this.selectDate = clicked.id;
          this.selectServices = services;
          this.showSelectServices = true;
        }
      },

      goToServiceDetail(serviceId) {
        const params = this.$route.params;
        this.$router.push({
          path: `/${params.operatorType}/${params.operatorId}/service/${serviceId}`,
        });
      },
    },

    mounted() {
      this.updateData(new Date());
    },
  };
</script>

<style lang="scss">
  .calendar-vendor {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .vendor-services {
    p {
      margin-bottom: 2rem;
    }

    button {
      display: block;
      width: 100%;
      margin: 0.5em 0;
      padding: 0.25em;
      background: #eee;
      color: #333;
      border: 1px solid #ddd;

      &:hover {
        background: rgb(255, 125, 125);
        color: #ffe7e7;
      }

      h5 {
        font-size: 1.5em;
        margin: 0.25em;
      }

      h6 {
        font-size: 1.2em;
        font-weight: 400;
        margin: 0.25em;
      }
    }
  }
</style>
